import { filter, find, forEach, includes, indexOf, map } from 'ramda'

import { createSlice } from '@reduxjs/toolkit'
import fetchBucketDataThunk from 'app/Views/Process/helpers/functions/fetch-bucket-data-thunk'
import fetchBucketFiltersThunk from 'app/Views/Process/helpers/functions/fetch-bucket-filters-thunk'
import { ResponseDataItems } from 'app/Views/Process/types'
import { DBState } from 'redux/db/types'
import { fetchSelectionData } from 'redux/db/utils/fetch-dropdown-items'
import formatApiDataToBeDispatched from 'redux/db/utils/format-api-data'
import { isNotNullOrUndefinedOrEmpty } from 'utils/helpers/null-checking/is-null-or-undefined'

export const initialState = {
  buckets: {
    OFFERED: {
      count: null,
      items: [],
    },
    INTERVIEWED: {
      count: null,
      items: [],
    },
    SHORTLISTED: {
      count: null,
      items: [],
    },
    APPLIED: {
      count: null,
      items: [],
    },
    AVAILABLE: {
      count: null,
      items: [],
    },
    PROGRESS: {
      count: null,
      items: [],
    },
    PLACED: {
      count: null,
      items: [],
    },
  },
  bucketFilters: {
    AGENT: [],
    INTERN: [],
    COMPANY: [],
  },
}

const db = createSlice({
  name: 'db',
  initialState: initialState as DBState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSelectionData.fulfilled, (state, action) => {
      formatApiDataToBeDispatched(state)(action.payload)
    })

    builder.addCase(fetchBucketDataThunk.fulfilled, (state, { payload }: { payload: any }) => {
      const { itemsList, totalItemsCount, stage, isSearchData } = payload
      state.buckets[stage].count = totalItemsCount

      let currentBucket = state.buckets[stage].items || []

      if (!!isSearchData) {
        state.buckets[stage].items = []
      }

      forEach((payloadData: ResponseDataItems) => {
        //because there is no code being sent out in the availble bucket.
        const availableBucketKey = payloadData?.code
        const bucketKey = payloadData?.code || ''
        const isAvailableStage = stage === 'AVAILABLE'
        let key: string = isAvailableStage ? availableBucketKey : bucketKey

        if (!key) {
          console.warn('triggered here if no key')
          return
        }

        if (!state[key]) {
          state[key] = payloadData
        }

        const noPreExistingKeyInBucket =
          isNotNullOrUndefinedOrEmpty(key) && indexOf(key)(currentBucket) === -1

        const noPreExistingKeyInBucketWhenSearched = !!isSearchData && !noPreExistingKeyInBucket

        if (noPreExistingKeyInBucketWhenSearched) {
          state.buckets[stage].items.push(key)
        }
        if (noPreExistingKeyInBucket) {
          state.buckets[stage].items.push(key)
        }
      })(itemsList || [])

      const itemListKeys = map((item: ResponseDataItems) => item?.code)(itemsList || [])

      const updatedItems = filter(
        (key: string) => includes(key, itemListKeys),
        state.buckets[stage].items,
      )

      state.buckets[stage].items = updatedItems

      forEach((key: string) => {
        const payloadData = find((item: ResponseDataItems) => item.code === key)(itemsList)

        if (payloadData) {
          // Update the object in the db store with the new data for this key in order for the correct actions to be displayed
          state[key] = payloadData
        }
      })(updatedItems)
    })

    builder.addCase(fetchBucketFiltersThunk.fulfilled, (state, { payload }: { payload: any }) => {
      const { itemsList, filterName } = payload || {}

      let currentFilter = state.bucketFilters[filterName]

      forEach((payloadData: any) => {
        //because there is no code being sent out in the availble bucket.

        let key: string = payloadData?.code || ''

        if (!!key && !state[key]) {
          state[key] = payloadData
        }

        const noPreExistingKeyInRelevantFilter =
          isNotNullOrUndefinedOrEmpty(key) && indexOf(key)(currentFilter) === -1

        if (noPreExistingKeyInRelevantFilter) {
          state.bucketFilters[filterName].push(key)
        }
      })(itemsList || [])
    })
  },
})
export default db.reducer
